<template>
  <div>
    <div v-if="application" class="admin-detail">
      <div class="admin-navbar align-right">
        <div class="detail-buttons">
          <button v-if="organizationEditable" class="detail-button"
                  @click="organizationEdit = true">
            {{ application.type === 'SCHOOL' ? $lang.app.change_school : $lang.app.change_preschool }}
          </button>
          <button v-if="languageEditable" class="detail-button bisque"
                  @click="languageEdit = true">
            {{ $lang.app.change_language }}
          </button>
          <button v-if="enrollable" class="detail-button green" @click="enroll = true">{{ $lang.app.enroll }}</button>
          <button v-if="rejectable"
                  :disabled="!rejectAvailable"
                  class="detail-button"
                  :class="[rejectAvailable ? 'red' : 'disabled']"
                  @click="reject = true">{{ $lang.app.reject }}</button>
<!--          <button v-if="archivable" class="detail-button" @click="archive = true">{{ $lang.app.archive }}</button>-->
<!--          <button v-if="unarchivable" class="detail-button" @click="unarchive">{{ $lang.app.unarchive }}</button>-->
          <button v-if="droppable" class="detail-button" @click="drop = true">{{ $lang.app.drop }}</button>
<!--          <button v-if="restorable" class="detail-button" @click="restore">{{ $lang.app.restore }}</button>-->
          <button v-if="restorableToWaiting" class="detail-button purple" @click="restoreToWaiting">{{ $lang.app.restore_to_waiting }}</button>
          <button v-if="restorableToWaitingEnrolling" class="detail-button pink" @click="restoreToWaitingEnrolling">{{ $lang.app.restore_to_waiting_enrolling }}</button>
          <BroughtDocumentModal v-if="schoolInquiryPrintable" :application="application"
                           @refresh="() => $emit('refresh')"/>
          <button v-if="schoolInquiryPrintable"
                  class="detail-button" @click="schoolInquiry = true">{{ $lang.app.inquiry }}</button>
          <button v-if="privilegesEditable" class="detail-button blue"
                  @click="privilegesEdit = true">
            {{ $lang.app.edit_privileges }}
          </button>
          <button v-if="cancelableByApplicant" class="detail-button red" @click="applicantCancel">{{ $lang.app.to_cancel }}</button>
        </div>
      </div>
      <div class="admin-info">
        <h4 class="info-title">{{ $lang.app.application_info }}</h4>
        <div class="info-fields">
          <div v-if="helper.types.length" class="info-field">
            <p class="field-label">{{ $lang.app.application_type }}:</p>
            <p class="field-value">
              {{ helper.types.find(type => type.value === application.type).label }}
            </p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.application_status }}:</p>
            <p class="field-value">
              {{ $lang.app[application.status.toLowerCase()] }}
            </p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.application_created_date }}:</p>
            <p class="field-value">
              {{ $moment(application.createdAt).format('DD.MM.YYYY HH:mm:ss') }}
            </p>
          </div>
          <div v-if="$store.getters.isPreschool" class="info-field">
            <p class="field-label">{{ $lang.app.application_enrolled_date }}:</p>
            <p class="field-value">
              {{ application.enrolledDate ? $moment(application.enrolledDate).format('DD.MM.YYYY HH:mm:ss') : '' }}
            </p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.application_comment }}:</p>
            <p class="field-value">
              {{ application.message }}
            </p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.education_language }}:</p>
            <p class="field-value">
              {{ application.language?.name }}
            </p>
          </div>
          <div v-if="$store.getters.isPreschool" class="info-field">
            <p class="field-label">{{ $lang.app.group }}:</p>
            <p v-if="application.organizationGroup && application.organizationGroup.preschoolGroupType" class="field-value">
              {{ $lang.$translate({kg: application.organizationGroup.preschoolGroupType.nameKg, ru: application.organizationGroup.preschoolGroupType.nameRu }) }}
            </p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.school_or_preschool }}:</p>
            <p class="field-value">
              {{ $lang.$translate({kg: application.organization.nameKG, ru: application.organization.nameRU }) }}
            </p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.archived }}:</p>
            <p class="field-value">
              {{ application.archived ? $lang.app.yes : $lang.app.no }}
            </p>
          </div>
          <div v-if="application.archived" class="info-field">
            <p class="field-label">{{ $lang.app.archived_comment }}:</p>
            <p class="field-value">
              {{ application.archivedMessage }}
            </p>
          </div>
          <div v-if="application.applicantPrivilegeId && helper.applicantPrivilegeTypes.length" class="info-field">
            <p class="field-label">{{ $lang.app.applicant_privilege_type }}:</p>
            <p class="field-value">
              {{ helper.applicantPrivilegeTypes.find(privilegeType => privilegeType.id === application.applicantPrivilegeId).title }}
            </p>
          </div>
          <div v-if="application.applicantPrivilegeId && application.applicantPrivilegeId !== 200" class="info-field">
            <p class="field-label">{{ $lang.app.privilege_file }}:</p>
            <p class="field-value">
              <a href @click.prevent="openFile(application.applicantPrivilegeFilename)">{{ application.applicantPrivilegeFilename }}</a>
            </p>
          </div>
          <div v-if="$store.getters.isPreschool && application.childPrivilegeId && helper.childPrivilegeTypes.length" class="info-field">
            <p class="field-label">{{ $lang.app.child_privilege_type }}:</p>
            <p class="field-value">
              {{ helper.childPrivilegeTypes.find(privilegeType => privilegeType.id === application.childPrivilegeId)?.title }}
            </p>
          </div>
          <div v-if="application.childPrivilegeId && application.childPrivilegeId !== 1" class="info-field">
            <p class="field-label">{{ $lang.app.privilege_file }}:</p>
            <p class="field-value">
              <a href @click.prevent="openFile(application.childPrivilegeFilename)">{{ application.childPrivilegeFilename }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <OrganizationEditModal v-if="organizationEdit"
               :application="application"
               @close="organizationEdit = false"
               @refresh="() => {
                  organizationEdit = false
                  $emit('refresh')
               }"/>

    <LanguageEditModal v-if="languageEdit"
                 :application="application"
                 @close="languageEdit = false"
                 @refresh="() => {
                  languageEdit = false
                  $emit('refresh')
               }"/>

    <EnrollModal v-if="enroll"
                 :application="application"
                 @close="enroll = false"
                 @refresh="() => {
                   enroll = false
                   $emit('refresh')
                 }"/>

    <RejectModal v-if="reject"
                 :application="application"
                 @close="reject = false"
                 @refresh="() => {
                   reject = false
                   $emit('refresh')
                 }"/>

    <ArchiveModal v-if="archive"
                 :application="application"
                 @close="archive = false"
                 @refresh="() => {
                   archive = false
                   $emit('refresh')
                 }"/>

    <DropModal v-if="drop"
                  :application="application"
                  @close="drop = false"
                  @refresh="() => {
                   drop = false
                   $emit('refresh')
                 }"/>

    <SchoolInquiryModal v-if="schoolInquiry"
                        :application="application"
                       @close="schoolInquiry = false"/>

    <PrivilegesEditModal v-if="privilegesEdit"
                        :application="application"
                        @close="privilegesEdit = false"
                        @refresh="() => {
                  privilegesEdit = false
                  $emit('refresh')
               }"/>

  </div>
</template>
<script>
import fileUploadMixin from "../../../mixins/fileUploadMixin"
import OrganizationEditModal from './OrganizationEditModal'
import LanguageEditModal from './LanguageEditModal'
import PrivilegesEditModal from './PrivilegesEditModal'
import EnrollModal from './EnrollModal'
import RejectModal from './RejectModal'
import ArchiveModal from './ArchiveModal'
import DropModal from './DropModal'
import SchoolInquiryModal from './SchoolInquiryModal'
import BroughtDocumentModal from "@/components/admin/application/BroughtDocumentModal";
export default {
  props: ['applicationData'],
  components: {
    BroughtDocumentModal,
    OrganizationEditModal,
    LanguageEditModal,
    PrivilegesEditModal,
    EnrollModal,
    RejectModal,
    ArchiveModal,
    DropModal,
    SchoolInquiryModal,
  },
  mixins: [fileUploadMixin],
  data() {
    return {
      application: this.applicationData,
      helper: {
        types: [
          {
            label: this.$lang.app.to_school,
            value: 'SCHOOL',
          },
          {
            label: this.$lang.app.to_preschool,
            value: 'PRESCHOOL',
          }
        ],
        applicantPrivilegeTypes: [],
        childPrivilegeTypes: [],
      },
      organizationEdit: false,
      languageEdit: false,
      privilegesEdit: false,
      enroll: false,
      reject: false,
      archive: false,
      drop: false,
      schoolInquiry: false,
    }
  },
  created() {
    this.setApplicantPrivilegeTypes()
    this.setChildPrivilegeTypes()
  },
  computed: {
    cancelableByApplicant() {
      if(this.$store.getters.isParent) {
        if(this.application.type === 'PRESCHOOL') {
          if(this.application.status !== 'ENROLLED') {
            return true
          }
        }
        if(this.application.type === 'SCHOOL') {
          return true
        }
      }

      return false
    },
    broughtDocumentRemarkable() {
      if (this.application.type === 'SCHOOL') {
        if (this.application.status === 'ENROLLED') {
          return true
        }
      }
      return false
    },
    organizationEditable() {
      if(this.$store.getters.isParent) {
        if(this.application.type === 'PRESCHOOL') {
          if(this.application.status !== 'ENROLLED') {
            return true
          }
        }
        if(this.application.type === 'SCHOOL') {
          if(this.$store.getters.registrationToSchoolOutsideResidenceAvailable) {
            return true
          }
        }
      }

      return false
    },
    languageEditable() {
      if(this.$store.getters.isParent) {
        if(this.application.type === 'PRESCHOOL') {
          if(['WAITING'].includes(this.application.status)) {
            return true
          }
        }
      }

      return false
    },
    privilegesEditable() {
      return this.$store.getters.isParent
    },
    enrollable() {
      if(this.$store.getters.isSchool) {
        if(['WAITING'].includes(this.application.status)) {
          return this.application.applicant.relationTypeId === 3 || this.hasPrivilege
        }
      }
      if(this.$store.getters.isPreschool) {
        if(['WAITING_ENROLLING'].includes(this.application.status)) {
          return true
        }
      }

      return false
    },
    rejectable() {
      if(this.$store.getters.isSchool) {
        if(this.application.status === 'WAITING') {
          return true
        }
      }
      if(this.$store.getters.isPreschool) {
        if(this.application.status === 'WAITING') {
          if(!this.hasPrivilege) {
            return true
          }
        }
        if(this.application.status === 'WAITING_ENROLLING') {
          return true
        }
      }
      return false
    },
    rejectAvailable() {

      if(this.$store.getters.isSchool) {
        if(['WAITING'].includes(this.application.status)) {
          return true;
        }
      }

      if(this.$store.getters.isPreschool) {
        if(['WAITING'].includes(this.application.status)) {
          return true
        }
        if(['WAITING_ENROLLING'].includes(this.application.status)) {
          if(this.$moment().subtract(2,'w').isAfter(this.application.currentStatus.registrationDate)){
            return true;
          }
        }
      }

      return false
    },
    archivable() {
      return this.$store.getters.isPreschool
          && !this.application.archived && this.application.status === 'ENROLLED'
    },
    droppable() {
      return this.$store.getters.isPreschool
          && this.application.status !== 'DROPPED' && this.application.status === 'ENROLLED'
    },
    unarchivable() {
      return this.$store.getters.isPreschool
          && this.application.archived && this.application.status === 'ENROLLED'
    },
    restorable() {
      return this.$store.getters.isPreschool && this.application.status === 'REJECTED'
    },
    restorableToWaiting() {
      return this.$store.getters.isPreschool && ['REJECTED', 'DROPPED'].includes(this.application.status)
    },
    restorableToWaitingEnrolling() {
      return this.$store.getters.isPreschool && ['REJECTED', 'DROPPED'].includes(this.application.status)
    },
    hasPrivilege() {
      return (this.application.applicantPrivilegeId && this.application.applicantPrivilegeId !== 200)
          || (this.application.childPrivilegeId && this.application.childPrivilegeId !== 1)
    },
    schoolInquiryPrintable() {
      return this.application.status === 'ENROLLED'
          && (this.$store.getters.isSchoolDirector || this.$store.getters.isSchoolEmployee)
    }
  },
  watch: {
    applicationData(value) {
      this.application = value
    }
  },
  methods: {
    setApplicantPrivilegeTypes() {
      this.$axios.get(`/privilege-type/list/by`, {
        params: {
          type: this.application.type,
          code: 'applicant_privilege_type',
          lang: this.$lang.$current
        }
      }).then(({data}) => {
        this.helper.applicantPrivilegeTypes = data
      })
    },
    setChildPrivilegeTypes() {
      this.$axios.get(`/privilege-type/list/by`, {
        params: {
          type: this.application.type,
          code: 'child_privilege_type',
          lang: this.$lang.$current
        }
      }).then(({data}) => {
        this.helper.childPrivilegeTypes = data
      })
    },
    openFile(filename) {
      this.downloadFile(filename).then(data => {
        let extension = filename.split('.').pop().toLowerCase();
        if(['png', 'jpg', 'jpeg'].includes(extension)) {
          let file = URL.createObjectURL(new Blob([data],{type: `image/${extension}`}));
          window.open(file, '_blank');
        }
        if(extension === 'pdf') {
          let file = URL.createObjectURL(new Blob([data],{type: 'application/pdf'}));
          window.open(file, '_blank');
        }
      })
    },
    restore() {
      this.$store.commit('startLoading')
      this.$axios.post(`/application/status/${this.application.id}/restore`).then(() => {
        this.$snotify.success(this.$lang.app.successfully_restored);
        this.$emit('refresh')
      }).catch(error => {
        if(error.response.status === 400) {
          this.$snotify.error(error)
        }
      }).finally(() => {
        this.$store.commit('stopLoading')
      })
    },
    restoreToWaiting() {
      this.$swal.fire({
        icon: 'warning',
        text: this.$lang.app.restore_to_waiting_confirm_question,
        showCancelButton: true,
        confirmButtonColor: '#4c9ac7',
        cancelButtonColor: '#6e7881',
        confirmButtonText: this.$lang.app.confirm,
        cancelButtonText: this.$lang.app.cancel,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.commit('startLoading')
          this.$axios.post(`/application/${this.application.id}/restore-to-waiting`).then(() => {
            this.$snotify.success(this.$lang.app.successfully_restored_to_waiting);
            this.$emit('refresh')
          }).catch(error => {
            if(error.response.status === 400) {
              this.$snotify.error(error)
            }
          }).finally(() => {
            this.$store.commit('stopLoading')
          })
        }
      })
    },
    restoreToWaitingEnrolling() {
      this.$swal.fire({
        icon: 'warning',
        text: this.$lang.app.restore_to_waiting_enrolling_confirm_question,
        showCancelButton: true,
        confirmButtonColor: '#4c9ac7',
        cancelButtonColor: '#6e7881',
        confirmButtonText: this.$lang.app.confirm,
        cancelButtonText: this.$lang.app.cancel,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.commit('startLoading')
          this.$axios.post(`/application/${this.application.id}/restore-to-waiting-enrolling`).then(() => {
            this.$snotify.success(this.$lang.app.successfully_restored_to_waiting_enrolling);
            this.$emit('refresh')
          }).catch(error => {
            if(error.response.status === 400) {
              this.$snotify.error(error)
            }
          }).finally(() => {
            this.$store.commit('stopLoading')
          })
        }
      })
    },
    unarchive() {
      this.$store.commit('startLoading')
      this.$axios.post(`/application/${this.application.id}/unarchive`).then(() => {
        this.$snotify.success(this.$lang.app.successfully_unarchived);
        this.$emit('refresh')
      }).catch(error => {
        if(error.response.status === 400) {
          this.$snotify.error(error)
        }
      }).finally(() => {
        this.$store.commit('stopLoading')
      })
    },
    applicantCancel() {
      this.$swal.fire({
        icon: 'warning',
        html: `<p><span>${this.$lang.app.application_cancel_confirm_text
            .replace(':fio', this.$store.getters.getUser.lastName + ' ' + this.$store.getters.getUser.firstName)
            .replace(':organization', this.$lang.$translate({ru: this.application.organization.nameRU, kg: this.application.organization.nameKG }))}
            </span><br><span>${this.$lang.app.application_cancel_cancel_text }</span></p><p>${this.$lang.app.application_cancel_warning_text}</p>`,
        showCancelButton: true,
        confirmButtonColor: '#4c9ac7',
        cancelButtonColor: '#6e7881',
        confirmButtonText: this.$lang.app.confirm,
        cancelButtonText: this.$lang.app.cancel,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.commit('startLoading')
          this.$axios.post(`/applicant/${this.application.applicant.id}/cancel-application/${this.application.id}`).then(() => {
            this.$snotify.success(this.$lang.app.successfully_canceled);
            this.$router.push(`/admin/applications`)
          }).catch(error => {
            if(error.response.status === 400) {
              this.$snotify.error(error)
            }
          }).finally(() => {
            this.$store.commit('stopLoading')
          })
        }
      })
    }
  }
}
</script>