<template>
  <div>
    <div class="admin-detail">
      <div class="admin-navbar align-right">
        <div class="detail-buttons">
          <button v-if="editable" class="detail-button"
                  @click="edit = true">
            {{ $lang.app.edit }}
          </button>
        </div>
      </div>
      <div v-if="children" class="admin-info">
        <h4 class="info-title">{{ $lang.app.children_info }}</h4>
        <div class="info-fields">
          <div class="info-field">
            <p class="field-label">{{ $lang.app.child_pin }}:</p>
            <p class="field-value">{{ children.pin }}</p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.child_full_name }}:</p>
            <p class="field-value">{{ children.lastName }} {{ children.firstName }} {{ children.middleName }}</p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.child_birth_date }}:</p>
            <p class="field-value">{{ $moment(children.dateOfBirth).format('DD.MM.YYYY') }}</p>
          </div>
          <div v-if="helper.genders.length" class="info-field">
            <p class="field-label">{{ $lang.app.child_gender }}:</p>
            <p class="field-value">
              {{ helper.genders.find(gender => gender.value === children.gender).label }}
            </p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.birth_certificate_seria_and_number }}:</p>
            <p class="field-value">{{ children.birthCertificateRange }} {{ children.birthCertificateId }}</p>
          </div>
        </div>
      </div>
    </div>
    <ChildrenEditModal v-if="edit"
               :children="children"
               :application="application"
               @close="edit = false"
               @refresh="() => {
                  edit = false
                  $emit('refresh')
               }"/>
  </div>
</template>
<script>
import ChildrenEditModal from './ChildrenEditModal'

export default {
  props: ['children', 'application'],
  components: {
    ChildrenEditModal
  },
  data() {
    return {
      helper: {
        genders: [
          {
            label: this.$lang.app.male,
            value: 'MALE'
          },
          {
            label: this.$lang.app.female,
            value: 'FEMALE'
          }
        ],
      },
      edit: false,
    }
  },
  computed: {
    editable() {
      return this.$store.getters.isPreschool && ['ENROLLED', 'WAITING_ENROLLING'].includes(this.application.status)
    }
  },
}
</script>