<template>
  <div class="modal">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content">
      <h4 class="create-title">{{ $lang.app.edit }}</h4>
      <div class="admin-form-wrapper">
        <div class="admin-form">
          <Form @submit="submit" :validation-schema="schema">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.child_citizenship }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <i class="bi-chevron-down"></i>
                <Field as="select" name="citizenship"
                       v-model="childrenForm.citizenship">
                  <option value="" disabled>{{ $lang.app.select_from }}</option>
                  <option v-for="citizenship in helper.citizenships" :key="citizenship.value"
                          :value="citizenship.value">{{ citizenship.label}}</option>
                </Field>
              </div>
              <ErrorMessage name="citizenship" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.pin }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="pin" type="text"
                       v-maska="'##############'"
                       v-model="childrenForm.pin"/>
                <div class="tooltip-target">
                  <i class="bi-question-circle"></i>
                  <div class="tooltip-content tooltip-content-small">
                    <p>{{ $lang.app.birth_certificate_des }}</p>
                    <p>{{ $lang.app.birth_certificate_example }}</p>
                    <div class="passport-images">
                      <div class="passport-image">
                        <img src="../../../assets/images/birth-cert.png" alt="">
                        <span>{{ $lang.app.birth_certificate }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ErrorMessage name="pin" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.surname }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="surname" type="text"
                       v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                       :title="$lang.app.only_cyrillic"
                       v-model="childrenForm.surname"
                       :disabled="true"/>
                <ExtraKyrgyzLetters v-if="false"
                                    @change="childrenForm.surname += $event"/>
              </div>
              <ErrorMessage name="surname" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.name }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="name" type="text"
                       v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                       :title="$lang.app.only_cyrillic"
                       v-model="childrenForm.name"
                       :disabled="true"/>
                <ExtraKyrgyzLetters v-if="false"
                                    @change="childrenForm.name += $event"/>
              </div>
              <ErrorMessage name="name" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.middle_name }} </label>
              <div class="reg-step-1-select-container">
                <Field name="middleName" type="text"
                       v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                       :title="$lang.app.only_cyrillic"
                       v-model="childrenForm.middleName"
                       :disabled="true"/>
                <ExtraKyrgyzLetters v-if="false"
                                    @change="childrenForm.middleName += $event"/>
              </div>
              <ErrorMessage name="middleName" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.birth_date }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="dateOfBirth" type="date"
                       v-model="childrenForm.dateOfBirth"/>
              </div>
              <ErrorMessage name="dateOfBirth" class="recovery-label-error" />
            </div>
            <div v-if="childrenForm.citizenship === 'KG'" key="KG" class="double-input">
              <div class="reg-step-form-item">
                <label class="recovery-label">{{ $lang.app.birth_certificate_seria }} <i>*</i></label>
                <div class="reg-step-1-select-container"><i class="bi-chevron-down"></i>
                  <Field as="select" name="birthCertificateRange"
                         v-model="childrenForm.birthCertificateRange">
                    <option value="" disabled>{{ $lang.app.select_from }}</option>
                    <option v-for="birthCertificateRange in helper.birthCertificateRanges" :key="birthCertificateRange"
                            :value="birthCertificateRange">{{ birthCertificateRange }}</option>
                  </Field>
                </div>
                <ErrorMessage name="birthCertificateRange" class="recovery-label-error" />
              </div>
              <div class="reg-step-form-item">
                <label class="recovery-label">{{ $lang.app.birth_certificate_number }} <i>*</i></label>
                <div class="reg-step-1-select-container">
                  <Field name="birthCertificateId" type="text"
                         v-maska="'#######'"
                         v-model="childrenForm.birthCertificateId"/>
                </div>
                <ErrorMessage name="birthCertificateId" class="recovery-label-error" />
              </div>
            </div>
            <div v-if="childrenForm.citizenship === 'FOREIGN'" key="FOREIGN" class="double-input">
              <div class="reg-step-form-item">
                <label class="recovery-label">{{ $lang.app.birth_certificate_seria }} <i>*</i></label>
                <div class="reg-step-1-select-container">
                  <Field name="birthCertificateRange" type="text"
                         v-model="childrenForm.birthCertificateRange"/>
                </div>
                <ErrorMessage name="birthCertificateRange" class="recovery-label-error" />
              </div>
              <div class="reg-step-form-item">
                <label class="recovery-label">{{ $lang.app.birth_certificate_number }} <i>*</i></label>
                <div class="reg-step-1-select-container">
                  <Field name="birthCertificateId" type="text"
                         v-model="childrenForm.birthCertificateId"/>
                </div>
                <ErrorMessage name="birthCertificateId" class="recovery-label-error" />
              </div>
            </div>

            <div class="reg-step-btn">
              <button type="submit">{{ $lang.app.update }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <button class="modal-close" @click="$emit('close')"><i class="bi-x-circle"></i></button>
  </div>
</template>
<script>
import { maska } from 'maska'
import { Field, Form, ErrorMessage } from 'vee-validate';
import ExtraKyrgyzLetters from '../../ExtraKyrgyzLetters.vue'
import * as yup from "yup";

export default {
  props: ['children', 'application'],
  components: {
    Field,
    Form,
    ErrorMessage,
    ExtraKyrgyzLetters,
  },
  directives: { maska },
  data() {
    return {
      childrenForm: {
        citizenship: null,
        pin: this.children.pin,
        surname: this.children.lastName,
        name: this.children.firstName,
        middleName: this.children.middleName,
        dateOfBirth: this.children.dateOfBirth,
        birthCertificateRange: this.children.birthCertificateRange,
        birthCertificateId: this.children.birthCertificateId,
      },
      schema: yup.object({
        pin: yup.string().min(14).
          required().matches(
            /[1,2][0-3][0-9][0-1][0-9][1,2][0,9][0-9]{7}/ 
          ),        surname: yup.string().required(),
        name: yup.string().required(),
        dateOfBirth: yup.mixed().required(),
        citizenship: yup.mixed().required(),
        birthCertificateRange: yup.mixed().when('citizenship', {
          is: (val) => ['KG', 'FOREIGN'].includes(val),
          then: yup.mixed().required(),
          otherwise: yup.mixed().nullable(),
        }),
        birthCertificateId: yup.mixed().when('citizenship', {
          is: (val) => ['KG', 'FOREIGN'].includes(val),
          then: yup.mixed().required(),
          otherwise: yup.mixed().nullable(),
        }),
      }),
      helper: {
        citizenships: [
          {
            label: this.$lang.app.child_citizen_kg,
            value: 'KG'
          },
          {
            label: this.$lang.app.child_citizen_foreign,
            value: 'FOREIGN'
          }
        ],
        birthCertificateRanges: ["КР-Х", 'KGZ01'],
      },
    }
  },
  methods: {
    submit(values, actions) {
      this.$store.commit('startLoading')
      this.$axios.post(`/children/edit-personal-data/${this.children.id}`, this.childrenForm)
          .then(() => {
            this.$snotify.success(this.$lang.app.resource_updated_successfully);
            this.$emit('refresh')
          }).catch(error => {
            if(error.response.status === 400) {
              actions.setErrors(error.response.data.errors);
            }
          }).finally(() => {
            this.$store.commit('stopLoading');
          })
    }
  }
}
</script>
<style>
@import '../../../assets/styles/modal.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
</style>