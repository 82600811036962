<template>
  <div class="modal">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="print-modal-content">
      <div class="print-modal-header">
        <h4 class="print-modal-title">{{ $lang.app.inquiry }}</h4>
        <button class="print-button" @click="print">{{ $lang.app.print }}</button>
      </div>
      <div class="print-content-wrapper">
        <div id="printContent" class="print-content">
          <h2 class="doc-title">Маалымдама<br>Справка</h2>
          <div class="doc-info">
            {{ application.organization.nameKG }} / {{ application.organization.nameRU }} / 20__.__.__ ж/г
          </div>
          <div class="doc-content">
            <p class="doc-text">Урматтуу / Уважаемый (ая) <i>{{ application.applicant.fio }}</i>
              Сиздин балаңыз (кызыңыз) / Ваш ребенок <i>{{ application.children.fio }}</i>
              мектепке кабыл алынды / принят в школу.
            </p>
            <p class="doc-text"><b>Тийиштүү документтерди алгандыгыбызды тастыктайбыз.<br>
              Подтверждаем, что получили соответствующие документы.</b>
            </p>
          </div>
          <div class="doc-owner">
            <div class="owner">
              <div class="owner-label">
                Мектептин директору: <br>
                Директор школы:
              </div>
              <div class="owner-name">{{ fullname }}</div>
            </div>
            <div class="signature">
              <div class="owner-label">
                Колу: <br>
                Подпись:
              </div>
              <div>____________</div>
            </div>
          </div>
          <div class="stamp">М.П /</div>
        </div>
      </div>
    </div>
    <button class="modal-close" @click="$emit('close')"><i class="bi-x-circle"></i></button>
  </div>
</template>
<script>

export default {
  props: ['application'],
  computed: {
    fullname() {
      let firstNameCapital = this.$store.getters.getUser.firstName ? this.$store.getters.getUser.firstName[0] : null
      let middleNameCapital = this.$store.getters.getUser.middleName ? this.$store.getters.getUser.middleName[0] : null
      let lastName = this.$store.getters.getUser.lastName ? this.$store.getters.getUser.lastName : ''
      return (firstNameCapital ? firstNameCapital + '.' : '') +
          (middleNameCapital ? middleNameCapital + '.' : '') + lastName
    }
  },
  methods: {
    print() {
      let win = window.open('','','left=0,top=0,width=1000,height=600,toolbar=0,scrollbars=0,status=0');

      let content = "<html><style>@page { size: auto;  margin: 2.0cm 1.7cm 2.0cm 2.5cm } .print-content {background: white;box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);font-family: \"Times New Roman\";font-size: 14px;padding: 60px 40px 60px 60px;}  .doc-title {font-weight: bold;text-align: center;text-transform: uppercase;margin-bottom: 20px;font-size: 14px;}  .doc-info {font-size: 14px;padding: 0 30px;margin-bottom: 20px;}  .doc-content {margin-bottom: 40px;}  .doc-text {text-align: justify;}  .doc-owner {text-align: left;display: flex;margin-bottom: 24px;}  .owner {width: 70%;display: flex;align-items: flex-end;}  .owner-label {width: 50%;}  .owner-name {width: 50%;}  .signature {width: 30%;display: flex;align-items: flex-end;}  .stamp {text-align: left;margin-bottom: 60px;} </style>";

      content += "<body onload=\"window.print(); window.close();\">";
      content += document.getElementById("printContent").innerHTML ;
      content += "</body>";
      content += "</html>";
      win.document.write(content);
      win.document.close();
    }
  }
}
</script>
<style scoped>
@import '../../../assets/styles/admin/print-modal.css';

.print-content {
  background: white;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  font-family: "Times New Roman";
  font-size: 14px;
  padding: 60px 40px 60px 60px;
}

.doc-title {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 14px;
}

.doc-info {
  font-size: 14px;
  padding: 0 30px;
  margin-bottom: 20px;
}

.doc-content {
  margin-bottom: 40px;
}

.doc-text {
  text-align: justify;
}

.doc-owner {
  text-align: left;
  display: flex;
  margin-bottom: 24px;
}

.owner {
  width: 70%;
  display: flex;
  align-items: flex-end;
}

.owner-label {
  width: 50%;
}

.owner-name {
  width: 50%;
}

.signature {
  width: 30%;
  display: flex;
  align-items: flex-end;
}

.stamp {
  text-align: left;
  margin-bottom: 60px;
}
</style>