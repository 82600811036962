<template>
  <div class="modal">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content">
      <h4 class="create-title">{{ $lang.app.edit_privileges }}</h4>
      <div class="admin-form-wrapper">
        <div class="admin-form">
          <Form @submit="submit" :validation-schema="schema" :initial-values="applicationForm">
            <div v-if="isSchool"
                 class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.applicant_privilege_type }} <i>*</i></label>
              <div class="reg-step-1-select-container select-control-wrapper">
                <i class="bi-chevron-down"></i>
                <Field name="applicantPrivilegeId" v-slot="{ field }">
                  <Multiselect
                      v-bind="field"
                      v-model="applicationForm.applicantPrivilegeId"
                      :options="helper.applicantPrivilegeTypes"
                      :placeholder="$lang.app.select_from"
                      valueProp="id"
                      label="title"
                      :noOptionsText="$lang.app.list_empty"
                      :noResultsText="$lang.app.not_found">
                  </Multiselect>
                </Field>
              </div>
              <ErrorMessage name="applicantPrivilegeId" class="recovery-label-error" />
            </div>
            <div v-if="isSchool && applicationForm.applicantPrivilegeId && applicationForm.applicantPrivilegeId !== 200"
                 class="file-input reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.privilege_file }}</label>
              <Field type="file" name="fileApplicantPrivilege" accept="application/pdf"
                     @change="event => applicationForm.fileApplicantPrivilege = event.target.files[0]"/>
              <ErrorMessage name="fileApplicantPrivilege" class="recovery-label-error"/>
            </div>
            <br>
            <div v-if="isPreschool"
                 class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.child_privilege_type }} <i>*</i></label>
              <div class="reg-step-1-select-container select-control-wrapper">
                <i class="bi-chevron-down"></i>
                <Field name="childPrivilegeId" v-slot="{ field }">
                  <Multiselect
                      v-bind="field"
                      v-model="applicationForm.childPrivilegeId"
                      :options="helper.childPrivilegeTypes"
                      :placeholder="$lang.app.select_from"
                      valueProp="id"
                      label="title"
                      :noOptionsText="$lang.app.list_empty"
                      :noResultsText="$lang.app.not_found">
                  </Multiselect>
                </Field>
              </div>
              <ErrorMessage name="childPrivilegeId" class="recovery-label-error" />
            </div>
            <div v-if="isPreschool && applicationForm.childPrivilegeId && applicationForm.childPrivilegeId !== 1"
                 class="file-input reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.privilege_file }}</label>
              <Field type="file" name="fileChildPrivilege" accept="application/pdf"
                     @change="event => applicationForm.fileChildPrivilege = event.target.files[0]"/>
              <ErrorMessage name="fileChildPrivilege" class="recovery-label-error"/>
            </div>
            <div class="reg-step-btn">
              <button type="submit">{{ $lang.app.change }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <button class="modal-close" @click="$emit('close')"><i class="bi-x-circle"></i></button>
  </div>
</template>
<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from "yup";

export default {
  props: ['application'],
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      helper: {
        applicantPrivilegeTypes: [],
        childPrivilegeTypes: [],
      },
      applicationForm: {
        applicantPrivilegeId: this.application.applicantPrivilegeId,
        fileApplicantPrivilege: null,
        childPrivilegeId: this.application.childPrivilegeId,
        fileChildPrivilege: null,
      },
      schema: yup.object({
        applicantPrivilegeId: yup.mixed().required(),
        fileApplicantPrivilege: yup.mixed().when('applicantPrivilegeId', {
          is: 200,
          then: yup.mixed().nullable(),
          otherwise: yup.mixed().required(),
        }),
        childPrivilegeId: yup.mixed().required(),
        fileChildPrivilege: yup.mixed().when('childPrivilegeId', {
          is: 1,
          then: yup.mixed().nullable(),
          otherwise: yup.mixed().required(),
        }),
      }),
    }
  },
  computed: {
    isPreschool() {
      return this.application.type === 'PRESCHOOL'
    },
    isSchool() {
      return this.application.type === 'SCHOOL'
    }
  },
  created() {
    this.setApplicantPrivilegeTypes()
    this.setChildPrivilegeTypes()
  },
  methods: {
    setApplicantPrivilegeTypes() {
      this.$axios.get(`/privilege-type/list/by`, {
        params: {
          type: this.application.type,
          code: 'applicant_privilege_type',
          lang: this.$lang.$current
        }
      }).then(({data}) => {
        this.helper.applicantPrivilegeTypes = data
      })
    },
    setChildPrivilegeTypes() {
      this.$axios.get(`/privilege-type/list/by`, {
        params: {
          type: this.application.type,
          code: 'child_privilege_type',
          lang: this.$lang.$current
        }
      }).then(({data}) => {
        this.helper.childPrivilegeTypes = data
      })
    },
    submit(values, actions) {

      if(this.applicationForm.fileApplicantPrivilege && this.applicationForm.fileApplicantPrivilege.size > 3000000) {
        actions.setErrors({fileApplicantPrivilege: this.$lang.app.file_size_limit_validation});
        return
      }
      if(this.applicationForm.fileChildPrivilege && this.applicationForm.fileChildPrivilege.size > 3000000) {
        actions.setErrors({fileChildPrivilege: this.$lang.app.file_size_limit_validation});
        return
      }

      this.$store.commit('startLoading')

      let formData = new FormData();
      formData.append('fileApplicantPrivilege', this.applicationForm.fileApplicantPrivilege);
      formData.append('fileChildPrivilege', this.applicationForm.fileChildPrivilege);

      this.$axios.post( `/application/${this.application.id}/update-privilege`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            params: {
              applicant_privilege_id: this.applicationForm.applicantPrivilegeId,
              child_privilege_id: this.applicationForm.childPrivilegeId
            }
          })
          .then(() => {
            this.$snotify.success(this.$lang.app.resource_updated_successfully);
            this.$emit('refresh')
          })
          .catch(error => {
            if(error.response.status === 400) {
              actions.setErrors(error.response.data.errors);
            }
          }).finally(() => {
            this.$store.commit('stopLoading');
          });
    }
  }
}
</script>
<style>
@import '../../../assets/styles/modal.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
</style>