<template>
  <div>
    <div class="admin-detail">
      <div class="admin-navbar align-right">
        <div class="detail-buttons">
          <button v-if="applicantAddable" class="detail-button" @click="addApplicant = true">
            {{ $lang.app.add_applicant }}
          </button>
          <button v-if="editable" class="detail-button" @click="edit = true">
            {{ $lang.app.edit }}
          </button>
          <button v-if="editableByApplicant" class="detail-button" @click="editByApplicant = true">
            {{ $lang.app.edit }}
          </button>
        </div>
      </div>
      <div v-if="applicant" class="admin-info">
        <h4 class="info-title">{{ $lang.app.applicant_info }}</h4>
        <div class="info-fields">
          <div v-if="helper.relationTypes.length" class="info-field">
            <p class="field-label">{{ $lang.app.applicant_type_label }}:</p>
            <p class="field-value">
              {{ helper.relationTypes.find(relationType => relationType.id === applicant.relationTypeId)?.title }}
            </p>
          </div>
          <div v-if="applicant.relationTypeId === 3" class="info-field">
            <p class="field-label">{{ $lang.app.legal_representative_file }}:</p>
            <p class="field-value">
              <a href @click.prevent="openFile(applicant.relationTypeFilename)">{{ applicant.relationTypeFilename }}</a>
            </p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.applicant_pin }}:</p>
            <p class="field-value">{{ applicant.pin }}</p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.applicant_fullname }}:</p>
            <p class="field-value">{{ applicant.lastName }} {{ applicant.firstName }} {{ applicant.middleName }}</p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.birth_date }}:</p>
            <p class="field-value">{{ $moment(applicant.dateOfBirth).format('DD.MM.YYYY') }}</p>
          </div>
          <div v-if="applicant.citizenshipValue === 'KG'" class="info-field">
            <p class="field-label">{{ $lang.app.passport_seria_and_number }}:</p>
            <p class="field-value">{{ applicant.passportRange }} {{ applicant.passportId }}</p>
          </div>
          <div v-if="applicant.citizenshipValue === 'FOREIGN'" class="info-field">
            <p class="field-label">{{ $lang.app.country_code_and_document }}:</p>
            <p class="field-value">{{ applicant.foreignCode }} {{ applicant.foreignDoc }}</p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.phone_number }}:</p>
            <p class="field-value">{{ applicant.phone }}</p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.applicant_email }}:</p>
            <p class="field-value">{{ applicant.email }}</p>
          </div>
<!--          <div class="info-field">-->
<!--            <p class="field-label">{{ $lang.app.second_applicant_email }}:</p>-->
<!--            <p class="field-value">{{ applicant.extraEmail }}</p>-->
<!--          </div>-->
          <div>
            <div class="info-field">
              <p class="field-label">{{ $lang.app.inquiry }}:</p>
              <p class="field-value">
                <a :class="{ disabled: loadingSpravka }" :aria-disabled="loadingSpravka" href
                  @click.prevent="openSpravkaFile('live')">Фактический адрес
                  проживания <span v-if="loadingSpravka === 'live'">Подождите идет
                    загрузка...</span></a>
              </p>
            </div>
            <div class="info-field">
              <p class="field-label">{{ $lang.app.inquiry }}:</p>
              <p class="field-value">
                <a :class="{ disabled: loadingSpravka }" :aria-disabled="loadingSpravka" href
                  @click.prevent="openSpravkaFile('family')">Состав семьи <span
                    v-if="loadingSpravka === 'family'">Подождите
                    идет
                    загрузка...</span></a> 
              </p>
            </div>
            <div class="info-field">
              <p  class="field-label"></p>
              <p class="field-value" v-if="messageSpravka">
                {{ messageSpravka }}
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
    <ApplicantEditModal v-if="edit" :applicant="applicant" :application="application" @close="edit = false" @refresh="() => {
      edit = false
      $emit('refresh')
    }" />
    <AddApplicantModal v-if="addApplicant" :applicant="applicant" :application="application" @close="edit = false"
      @refresh="() => {
        edit = false
        $emit('refresh')
      }" />
    <ByApplicantEditModal v-if="editByApplicant" :applicant="applicant" @close="editByApplicant = false" @refresh="() => {
      editByApplicant = false
      $emit('refresh')
    }" />
  </div>
</template>
<script>
import ApplicantEditModal from "./ApplicantEditModal";
import ByApplicantEditModal from "./ByApplicantEditModal";
import fileUploadMixin from "../../../mixins/fileUploadMixin";
import AddApplicantModal from "./AddApplicantModal";

export default {
  props: ['applicant', 'application'],
  components: {
    AddApplicantModal,
    ApplicantEditModal,
    ByApplicantEditModal
  },
  mixins: [fileUploadMixin],
  data() {
    return {
      helper: {
        relationTypes: [],
      },
      edit: false,
      editByApplicant: false,
      addApplicant: false,
      loadingSpravka: false,
      messageSpravka: '',
    }
  },
  created() {
    this.setRelationTypes()
  },
  computed: {
    editable() {
      return this.$store.getters.isPreschool && ['ENROLLED', 'WAITING_ENROLLING'].includes(this.application.status)
    },
    editableByApplicant() {
      return this.$store.getters.isParent
    },
    applicantAddable() {
      return this.$store.getters.isPreschool && this.application.status === 'WAITING_ENROLLING' && this.application.applicant === null
    },
    disabledClass: () => {
      return this.loadingSpravka ? "disabled" : ""
    }
  },
  methods: {
    setRelationTypes() {
      this.$axios.get(`/relation-type/get?lang=${this.$lang.$current}`).then(({ data }) => {
        this.helper.relationTypes = data
      })
    },
    openFile(filename) {
      this.downloadFile(filename).then(data => {
        let extension = filename.split('.').pop().toLowerCase();
        if (['png', 'jpg', 'jpeg'].includes(extension)) {
          let file = URL.createObjectURL(new Blob([data], { type: `image/${extension}` }));
          window.open(file, '_blank');
        }
        if (extension === 'pdf') {
          let file = URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
          window.open(file, '_blank');
        }
      })
    },
    openSpravkaFile(reportType) {
      if (!this.loadingSpravka) {
        this.loadingSpravka = reportType
        this.messageSpravka = ''
        fetch('/spravka/api/v1/spravka/search', {
          method: "POST",
          // credentials: "same-origin",
          credentials: "include",
          // mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.$store.getters.getToken}`
          },
          body: JSON.stringify({ pin: this.applicant.pin, reportType })
        }).then((response) => {
          return response.json();
        }).then((result) => {
          console.log(result)
          if (result.message) {
            console.log(result.message)
            this.messageSpravka = result.message
          }
          if (result.data) {
            this.messageSpravka = ''
            window.open(`/spravka/public/pdf/${result.data}`, '_blank');
          }
        }).catch((e) => {
          console.log(e)
        }).finally(() => {
          this.loadingSpravka = false
        })
      }
    }
  }
}
</script>

<style>
.disabled,
.disabled:hover {
  cursor: progress;
  color: gray
}
</style>