<template>
  <div class="modal">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content">
      <h4 class="create-title">{{ $lang.app.change_language }}</h4>
      <div class="admin-form-wrapper">
        <div class="admin-form">
          <Form @submit="submit">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.language_label }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <i class="bi-chevron-down"></i>
                <Field as="select" name="languageId"
                       v-model="editForm.languageId">
                  <option value="" disabled>{{ $lang.app.select_from }}</option>
                  <option v-for="language in helper.languages" :key="language.id"
                          :value="language.id">{{ language.title }}</option>
                </Field>
              </div>
            </div>

            <div class="reg-step-btn">
              <button type="submit">{{ $lang.app.change }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <button class="modal-close" @click="$emit('close')"><i class="bi-x-circle"></i></button>
  </div>
</template>
<script>
import { Field, Form } from 'vee-validate';

export default {
  components: {
    Field,
    Form,
  },
  props: ['application'],
  data() {
    return {
      editForm: {
        id: this.application.id,
        languageId: this.application.language ? this.application.language.id : null,
      },
      helper: {
        languages: [],
      },
    }
  },
  created() {
    this.setLanguages()
  },
  methods: {
    setLanguages() {
      this.helper.languages = this.application.organization.languages.map(item => {
        return {
          id: item.id,
          title: this.$lang.$translate({kg: item.nameKg, ru: item.name})
        }
      })
    },
    submit(values, actions) {
      this.$swal.fire({
        icon: 'warning',
        text: this.$lang.app.application_organization_language_change_confirm_question,
        showDenyButton: true,
        confirmButtonColor: '#4c9ac7',
        denyButtonColor: '#6e7881',
        confirmButtonText: this.$lang.app.yes,
        denyButtonText: this.$lang.app.no,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$axios.post(`/application/${this.application.id}/change-study-language?languageId=${this.editForm.languageId}`).then(() => {
            this.$snotify.success(this.$lang.app.application_language_successfully_changed);
            this.$emit('refresh')
          }).catch(error => {
            if(error.response.status === 400) {
              actions.setErrors(error.response.data.errors);
            }
          })
        } else if(result.isDenied) {
          this.$emit('close')
        }
      })
    }
  }
}
</script>
<style>
@import '../../../assets/styles/modal.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
@import '../../../assets/styles/multiselect.css';
.info-block {
  margin: 10px 0;
}
.info-block i {
  font-size: 30px;
  margin-right: 10px;
}
.info-block .info-message {
  color: dodgerblue;
  display: flex;
  align-items: center;
}
.info-block .exclamation-message {
  color: indianred;
  display: flex;
  align-items: center;
}
</style>