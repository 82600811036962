<template>
  <div class="modal">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content">
      <h4 class="create-title">{{ application.type === 'SCHOOL' ? $lang.app.change_school : $lang.app.change_preschool }}</h4>
      <div class="admin-form-wrapper">
        <div class="admin-form">
          <Form @submit="submit">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.region_city }}</label>
              <div class="reg-step-1-select-container">
                <i class="bi-chevron-down"></i>
                <Field as="select" name="regionId"
                       v-model="tempForm.regionId">
                  <option v-for="region in helper.regions" :key="region.id"
                          :value="region.id">{{ region.label }}</option>
                </Field>
              </div>
            </div>
            <div v-if="helper.districts.length" class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.raion }}</label>
              <div class="reg-step-1-select-container">
                <i class="bi-chevron-down"></i>
                <Field as="select" name="districtId"
                       v-model="tempForm.districtId">
                  <option v-for="district in helper.districts" :key="district.id"
                          :value="district.id">{{ district.label }}</option>
                </Field>
              </div>
            </div>
<!--            <div v-if="helper.aymaks.length" class="reg-step-form-item">-->
<!--              <label class="recovery-label">{{ $lang.app.aimak }}</label>-->
<!--              <div class="reg-step-1-select-container">-->
<!--                <i class="bi-chevron-down"></i>-->
<!--                <Field as="select" name="aymakId"-->
<!--                       v-model="tempForm.aymakId">-->
<!--                  <option v-for="aymak in helper.aymaks" :key="aymak.id"-->
<!--                          :value="aymak.id">{{ aymak.label }}</option>-->
<!--                </Field>-->
<!--              </div>-->
<!--            </div>-->
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.organization_selection }} <i>*</i></label>
              <div class="reg-step-1-select-container select-control-wrapper">
                <i class="bi-chevron-down"></i>
                <Multiselect
                    name="organizationId"
                    v-model="editForm.organizationId"
                    :options="helper.organizations"
                    :placeholder="$lang.app.select_from"
                    valueProp="id"
                    :searchable="true"
                    :noOptionsText="$lang.app.list_empty"
                    :noResultsText="$lang.app.not_found">
                  <template v-slot:option="{ option }">
                    <div>
                      <div class="multiselect-custom-label">{{ option.label }}</div>
                      <div class="multiselect-custom-sublabel">{{ option.address }}</div>
                    </div>
                  </template>
                </Multiselect>
              </div>
            </div>
            <div v-if="editForm.organizationId" class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.language_label }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <i class="bi-chevron-down"></i>
                <Field as="select" name="languageId"
                       v-model="editForm.languageId">
                  <option value="" disabled>{{ $lang.app.select_from }}</option>
                  <option v-for="language in helper.languages" :key="language.id"
                          :value="language.id">{{ language.title }}</option>
                </Field>
              </div>
            </div>
            <div v-if="application.type === 'PRESCHOOL' && editForm.organizationId && this.preschoolHasShortTerm()" class="reg-step-form-item">
              <div class="checkbox-container">
                <Field type="checkbox" name="shortTermGroup"
                       id="shortTermGroup"
                       @input="editForm.shortTermGroup = $event.target.checked"
                       :value="true"/>
                <label for="shortTermGroup">
                  <span>{{ $lang.app.short_term_group }}</span>
                </label>
              </div>
            </div>

            <div v-if="info.length" class="info-block">
              <div v-for="(infoItem, index) in info" :key="index">
                <div v-if="infoItem.success" class="info-message">
                  <i class="bi-info-circle"></i>
                  {{ infoItem.message }}
                </div>
                <div v-else class="exclamation-message">
                  <i class="bi-exclamation-circle"></i>
                  {{ infoItem.message }}
                </div>
              </div>
            </div>

            <div class="reg-step-btn">
              <button type="submit" :disabled="!isReadyToSubmit">{{ $lang.app.change }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <button class="modal-close" @click="$emit('close')"><i class="bi-x-circle"></i></button>
  </div>
</template>
<script>
import { Field, Form } from 'vee-validate';

export default {
  components: {
    Field,
    Form,
  },
  props: ['application'],
  data() {
    return {
      tempForm: {
        regionId: null,
        districtId: null,
        aymakId: null,
      },
      editForm: {
        applicationId: this.application.id,
        organizationId: '',
        languageId: '',
        shortTermGroup: false,
        organizationGroupId: null,
      },
      helper: {
        regions: [],
        districts: [],
        aymaks: [],
        organizations: [],
        languages: [],
      },
      info: []
    }
  },
  created() {
    this.fetchChild(4948);
  },
  computed: {
    isReadyToSubmit() {
      return this.info.length && !this.info.find(item => {
        return !item.isReadyToSubmit
      })
    }
  },
  watch: {
    'helper.organizations'() {
      this.editForm.organizationId = null
    },
    'tempForm.regionId'(value) {
      this.tempForm.districtId = null;
      this.tempForm.aymakId = null;
      this.helper.districts = [];
      this.helper.aymaks = [];
      if(value) {
        this.fetchChild(value)
        this.setOrganizations()
      }
    },
    'tempForm.districtId'(value) {
      this.tempForm.aymakId = null;
      this.helper.aymaks = [];
      if(value) {
        this.fetchChild(value)
        this.setOrganizations()
      }
    },
    'tempForm.aymakId'(value) {
      if(value) {
        this.setOrganizations()
      }
    },
    'editForm.organizationId'(value) {
      if(value) {
        this.editForm.languageId = null
        this.editForm.shortTermGroup = false
        this.getOrganizationLanguages(value)
      }
    },
    'editForm.languageId'() {
      this.info = []
      this.editForm.shortTermGroup = false
      this.getQuota()
    },
    'editForm.shortTermGroup'() {
      this.info = []
      this.getQuota()
    },
  },
  methods: {
    preschoolHasShortTerm() {
      for(const language of this.helper.languages){
        if(this.editForm.languageId !== null
            && language.id === this.editForm.languageId
            && language.hasShortTerm === 'true'){
          return true;
        }
      }
      return false;
    },
    fetchChild(id) {
      return this.$axios.get(`/address-service/children?parent=${id}`)
          .then(({data}) => {
            if (data.length > 0) {
              switch (data[0].type) {
                case 5:
                case 2:
                  this.helper.regions = this.getParsed(data)
                  break;
                case 3:
                case 6:
                  this.helper.districts = this.getParsed(data);
                  break;
                case 4:
                case 7:
                case 101:
                  this.helper.aymaks = this.getParsed(data)
                  break;
              }
            }
          })
    },
    getParsed(data) {
      return data.map(item => {
        return {
          id: item.id,
          label: this.$lang.$translate({
            ru: item.nameRu,
            kg: item.nameKg + ' ' + item.typeKgShort
          })
        }
      })
    },
    setOrganizations() {
      this.$store.commit('startLoading')
      this.$axios.get('/organization/filter', {
        params: {
          regionId: this.tempForm.regionId,
          districtId: this.tempForm.districtId,
          aimakId: this.tempForm.aymakId,
          type: this.application.type,
        }
      }).then(({data}) => {
        this.helper.organizations = this.getParsedOrganizations(data)
      }).finally(() => {
        this.$store.commit('stopLoading');
      })
    },
    getParsedOrganizations(data) {
      return data.map(item => {
        return {
          id: item.id,
          label: this.$lang.$translate({ru: item.nameRU, kg: item.nameKG }),
          address: this.$lang.$translate({ru: item.fullAddressRU, kg: item.fullAddressKG })
        }
      })
    },
    getOrganizationLanguages(organizationId) {
      this.$axios.get(`/lang/organization-group/${organizationId}/organization`)
          .then(({data}) => {
            this.helper.languages = data
          })
    },
    getQuota() {
      if(this.editForm.languageId) {
        this.$axios.post('/organization/get-available-quota', {
          applicationId: this.editForm.applicationId,
          languageId: this.editForm.languageId,
          organizationId: this.editForm.organizationId,
          shortTermGroup: this.editForm.shortTermGroup,
        }).then(({data}) => {
          if(data.message) {
            this.editForm.organizationGroupId = data.organizationGroupId
            this.info.push({
              ...data,
              isReadyToSubmit: true
            })
          }
        }).catch(error => {
          if(error.response.data.status === 404) {
            this.editForm.organizationGroupId = null
            this.info.push({
              success: false,
              message: error.response.data.message,
              isReadyToSubmit: false
            })
          }
        })
      }
    },
    submit(values, actions) {
      this.$swal.fire({
        icon: 'warning',
        text: this.application.type === 'PRESCHOOL'
            ? this.$lang.app.application_preschool_change_confirm_question
            : this.$lang.app.application_school_change_confirm_question,
        showDenyButton: true,
        confirmButtonColor: '#4c9ac7',
        denyButtonColor: '#6e7881',
        confirmButtonText: this.$lang.app.yes,
        denyButtonText: this.$lang.app.no,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$axios.post('/application/organization', {
            applicationId: this.editForm.applicationId,
            organizationGroupId: this.editForm.organizationGroupId,
            organizationId: this.editForm.organizationId,
            languageId: this.editForm.languageId,
            shortTermGroup: this.editForm.shortTermGroup
          }).then(() => {
            this.$snotify.success(this.application.type === 'PRESCHOOL'
                ? this.$lang.app.application_preschool_successfully_changed
                : this.$lang.app.application_school_successfully_changed);
            this.$emit('refresh')
          }).catch(error => {
            if(error.response.status === 400) {
              actions.setErrors(error.response.data.errors);
            }
          })
        } else if(result.isDenied) {
          this.$emit('close')
        }
      })
    }
  }
}
</script>
<style>
@import '../../../assets/styles/modal.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
@import '../../../assets/styles/multiselect.css';
.info-block {
  margin: 10px 0;
}
.info-block i {
  font-size: 30px;
  margin-right: 10px;
}
.info-block .info-message {
  color: dodgerblue;
  display: flex;
  align-items: center;
}
.info-block .exclamation-message {
  color: indianred;
  display: flex;
  align-items: center;
}
</style>