<template>
  <div class="modal">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content">
      <h4 class="create-title">{{ $lang.app.edit }}</h4>
      <div class="admin-form-wrapper">
        <div class="admin-form">
          <Form @submit="submit" :validation-schema="schema">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.phone_number }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="phone" type="text" autocomplete="off"
                       v-maska="'+996(###)-##-##-##'"
                       placeholder="+996(XXX)-XX-XX-XX"
                       v-model="applicantForm.phone"/>
              </div>
              <ErrorMessage name="phone" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.applicant_email }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="email" type="email"
                       v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[^а-яА-Я]/ }}}"
                       v-model="applicantForm.email"/>
              </div>
              <ErrorMessage name="email" class="recovery-label-error" />
            </div>
<!--            <div class="reg-step-form-item">-->
<!--              <label class="recovery-label">{{ $lang.app.second_applicant_email }}</label>-->
<!--              <div class="reg-step-1-select-container">-->
<!--                <Field name="extraEmail" type="email"-->
<!--                       v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[^а-яА-Я]/ }}}"-->
<!--                       v-model="applicantForm.extraEmail"/>-->
<!--              </div>-->
<!--              <ErrorMessage name="extraEmail" class="recovery-label-error" />-->
<!--            </div>-->
            <div class="reg-step-btn">
              <button type="submit">{{ $lang.app.update }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <button class="modal-close" @click="$emit('close')"><i class="bi-x-circle"></i></button>
  </div>
</template>
<script>
import { maska } from 'maska'
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from "yup";

export default {
  props: ['applicant'],
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  directives: { maska },
  data() {
    return {
      applicantForm: {
        phone: this.applicant.phone,
        email: this.applicant.email,
        extraEmail: this.applicant.extraEmail,
      },
      schema: yup.object({
        phone: yup.string().required()
            .matches(/\+996\([0-9]{3}\)-[0-9]{2}-[0-9]{2}-[0-9]{2}/, { excludeEmptyString: true }),
        email: yup.string().required().email(),
        extraEmail: yup.string().nullable().email(),
      }),
    }
  },
  methods: {
    submit(values, actions) {
      this.$store.commit('startLoading')
      this.$axios.post(`/applicant/edit/${this.applicant.id}`, {
        ...this.applicantForm,
        phone: this.applicantForm.phone.replace(/\D/g, "")
      })
          .then(() => {
            this.$snotify.success(this.$lang.app.resource_updated_successfully);
            this.logout()
            this.$emit('refresh')
          }).catch(error => {
        if(error.response.status === 400) {
          actions.setErrors(error.response.data.errors);
        }
      }).finally(() => {
        this.$store.commit('stopLoading');
      })
    },
    logout() {
      localStorage.removeItem('token')
      this.$store.commit('logout')
      this.$router.push('/')
    }
  }
}
</script>
<style>
@import '../../../assets/styles/modal.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
</style>