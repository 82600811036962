<template>
  <div class="modal">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content">
      <h4 class="create-title">{{ $lang.app.edit }}</h4>
      <div class="admin-form-wrapper">
        <div class="admin-form">
          <Form @submit="submit" :validation-schema="schema" :initial-values="applicantForm">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.applicant_type_label }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <i class="bi-chevron-down"></i>
                <Field as="select" name="relationTypeId"
                       v-model="applicantForm.relationTypeId">
                  <option value="" disabled>{{ $lang.app.select_from }}</option>
                  <option v-for="relationType in helper.relationTypes" :key="relationType.id"
                          :value="relationType.id">{{ relationType.title }}</option>
                </Field>
              </div>
              <ErrorMessage name="relationTypeId" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.citizenship }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <i class="bi-chevron-down"></i>
                <Field as="select" name="citizenship"
                       v-model="applicantForm.citizenship">
                  <option value="" disabled>{{ $lang.app.select_from }}</option>
                  <option v-for="citizenship in helper.citizenships" :key="citizenship.value"
                          :value="citizenship.value">{{ citizenship.label}}</option>
                </Field>
              </div>
              <ErrorMessage name="citizenship" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.pin }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="pin" type="text"
                       v-maska="'##############'"
                       v-model="applicantForm.pin"/>
                <div class="tooltip-target">
                  <i class="bi-question-circle"></i>
                  <div class="tooltip-content">
                    <p>{{ $lang.app.id_card_des }}</p>
                    <p>{{ $lang.app.id_card_example }}</p>
                    <div class="passport-images">
                      <div class="passport-image">
                        <img src="../../../assets/images/pin-2017.png" alt="">
                        <span>{{ $lang.app.id_card }}</span>
                      </div>
                      <div class="passport-image">
                        <img src="../../../assets/images/pin-2004.png" alt="">
                        <span>{{ $lang.app.passport }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ErrorMessage name="pin" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.surname }} <i>*</i></label>
              <div class="reg-step-1-select-container with-letter-buttons">
                <Field name="surname" type="text"
                       v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                       :title="$lang.app.only_cyrillic"
                       v-model="applicantForm.surname"
                       :disabled="true"/>
                <ExtraKyrgyzLetters v-if="false"
                                    @change="applicantForm.surname += $event"/>
              </div>
              <ErrorMessage name="surname" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.name }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="name" type="text"
                       v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                       :title="$lang.app.only_cyrillic"
                       v-model="applicantForm.name"
                       :disabled="true"/>
                <ExtraKyrgyzLetters v-if="false"
                                    @change="applicantForm.name += $event"/>
              </div>
              <ErrorMessage name="name" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.middle_name }} </label>
              <div class="reg-step-1-select-container">
                <Field name="middleName" type="text"
                       v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                       :title="$lang.app.only_cyrillic"
                       v-model="applicantForm.middleName"
                       :disabled="true"/>
                <ExtraKyrgyzLetters v-if="false"
                                    @change="applicantForm.middleName += $event"/>
              </div>
              <ErrorMessage name="middleName" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.birth_date }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="dateOfBirth" type="date"
                       v-model="applicantForm.dateOfBirth"/>
              </div>
              <ErrorMessage name="dateOfBirth" class="recovery-label-error" />
            </div>
            <div v-if="applicantForm.citizenship === 'KG'" key="KG"
                 class="double-input">
              <div class="reg-step-form-item">
                <label class="recovery-label">{{ $lang.app.passport_seria }} <i>*</i></label>
                <div class="reg-step-1-select-container">
                  <i class="bi-chevron-down"></i>
                  <Field as="select" name="passportRange"
                         v-model="applicantForm.passportRange">
                    <option value="" disabled>{{ $lang.app.select_from }}</option>
                    <option v-for="passportRange in helper.passportRanges" :key="passportRange"
                            :value="passportRange">{{ passportRange }}</option>
                  </Field>
                </div>
                <ErrorMessage name="passportRange" class="recovery-label-error" />
              </div>
              <div class="reg-step-form-item">
                <label class="recovery-label">{{ $lang.app.passport_number }} <i>*</i></label>
                <div class="reg-step-1-select-container">
                  <Field name="passportId" type="text"
                         v-maska="'#######'"
                         v-model="applicantForm.passportId"/>
                  <div class="tooltip-target">
                    <i class="bi-question-circle"></i>
                    <div class="tooltip-content tooltip-content-small">
                      <p>{{ $lang.app.passport_number_des }}</p>
                      <p>{{ $lang.app.passport_number_example }}</p>
                      <div class="passport-images">
                        <div class="passport-image">
                          <img src="../../../assets/images/id-2017.png" alt="">
                          <span>{{ $lang.app.id_card }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ErrorMessage name="passportId" class="recovery-label-error" />
              </div>
            </div>
            <div v-if="applicantForm.citizenship === 'FOREIGN'" key="FOREIGN"
                 class="double-input">
              <div class="reg-step-form-item">
                <label class="recovery-label">{{ $lang.app.country_code }} <i>*</i></label>
                <div class="reg-step-1-select-container select-control-wrapper">
                  <i class="bi-chevron-down"></i>
                  <Field name="foreignCode" v-slot="{ field }">
                    <Multiselect
                        v-bind="field"
                        v-model="applicantForm.foreignCode"
                        :options="helper.countryCodes"
                        :placeholder="$lang.app.select_from"
                        valueProp="code"
                        label="title"
                        :searchable="true"
                        :noOptionsText="$lang.app.list_empty"
                        :noResultsText="$lang.app.not_found">
                    </Multiselect>
                  </Field>
                </div>
                <ErrorMessage name="foreignCode" class="recovery-label-error" />
              </div>
              <div class="reg-step-form-item">
                <label class="recovery-label">{{ $lang.app.document_number }} <i>*</i></label>
                <div class="reg-step-1-select-container">
                  <Field name="foreignDoc" type="text"
                         v-model="applicantForm.foreignDoc"/>
                </div>
                <ErrorMessage name="foreignDoc" class="recovery-label-error" />
              </div>
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.phone_number }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="phone" type="text" autocomplete="off"
                       v-model="applicantForm.phone"/>
              </div>
              <ErrorMessage name="phone" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.applicant_email }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="email" type="email"
                       v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[^а-яА-Я]/ }}}"
                       v-model="applicantForm.email"/>
              </div>
              <ErrorMessage name="email" class="recovery-label-error" />
            </div>
            <div class="reg-step-btn">
              <button type="submit">{{ $lang.app.update }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <button class="modal-close" @click="$emit('close')"><i class="bi-x-circle"></i></button>
  </div>
</template>
<script>
import { maska } from 'maska'
import { Field, Form, ErrorMessage } from 'vee-validate';
import ExtraKyrgyzLetters from '../../ExtraKyrgyzLetters.vue'
import * as yup from "yup";

export default {
  props: ['applicant', 'application'],
  components: {
    Field,
    Form,
    ErrorMessage,
    ExtraKyrgyzLetters,
  },
  directives: { maska },
  data() {
    return {
      applicantForm: {
        relationTypeId: this.applicant.relationTypeId,
        citizenship: this.applicant.citizenshipValue,
        pin: this.applicant.pin,
        surname: this.applicant.lastName,
        name: this.applicant.firstName,
        middleName: this.applicant.middleName,
        dateOfBirth: this.applicant.dateOfBirth,
        passportRange: this.applicant.passportRange,
        passportId: this.applicant.passportId ?? '',
        foreignCode: this.applicant.foreignCode,
        foreignDoc: this.applicant.foreignDoc ?? '',
        phone: this.applicant.phone,
        email: this.applicant.email,
      },
      schema: yup.object({
        relationTypeId: yup.mixed().required(),
        citizenship: yup.mixed().required(),
        pin: yup.string().min(14).
          required().matches(
            /[1,2][0-3][0-9][0-1][0-9][1,2][0,9][0-9]{7}/ 
          ),
        surname: yup.string().required(),
        name: yup.string().required(),
        dateOfBirth: yup.mixed().required(),
        passportRange: yup.mixed().when('citizenship', {
          is: 'KG',
          then: yup.mixed().required(),
          otherwise: yup.mixed().nullable(),
        }),
        passportId: yup.mixed().when('citizenship', {
          is: 'KG',
          then: yup.string().required().min(7),
          otherwise: yup.string().nullable(),
        }),
        foreignCode: yup.mixed().when('citizenship', {
          is: 'FOREIGN',
          then: yup.mixed().required(),
          otherwise: yup.string().nullable(),
        }),
        foreignDoc: yup.mixed().when('citizenship', {
          is: 'FOREIGN',
          then: yup.string().required(),
          otherwise: yup.string().nullable(),
        }),
        phone: yup.string().required(),
        email: yup.string().required().email(),
      }),
      helper: {
        relationTypes: [],
        citizenships: [
          {
            label: this.$lang.app.citizen_kg,
            value: 'KG'
          },
          {
            label: this.$lang.app.citizen_other,
            value: 'FOREIGN'
          }
        ],
        passportRanges: ['AN', 'ID'],
        countryCodes: [],
      },
    }
  },
  created() {
    this.setRelationTypes()
    this.setCountryCodes()
  },
  methods: {
    setRelationTypes() {
      this.$axios.get(`/relation-type/get?lang=${this.$lang.$current}`).then(({data}) => {
        this.helper.relationTypes = data
      })
    },
    setCountryCodes() {
      this.$axios.get(`/country`).then(({data}) => {
        this.helper.countryCodes = data
      })
    },
    submit(values, actions) {
      this.$store.commit('startLoading')
      this.$axios.post(`/applicant/edit-personal-info/${this.applicant.id}`, this.applicantForm)
          .then(() => {
            this.$snotify.success(this.$lang.app.resource_updated_successfully);
            this.$emit('refresh')
          }).catch(error => {
            if(error.response.status === 400) {
              actions.setErrors(error.response.data.errors);
            }
          }).finally(() => {
            this.$store.commit('stopLoading');
          })
    }
  }
}
</script>
<style>
@import '../../../assets/styles/modal.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
</style>