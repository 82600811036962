<template>
  <div class="modal">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content">
      <h4 class="create-title">{{ $lang.app.enroll }}</h4>
      <div class="admin-form-wrapper">
        <div class="admin-form">
          <Form @submit="submit">

            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.enroll_comment_label }}</label>
              <div>
                <Field name="message" v-slot="{ field }">
                    <textarea class="form-textarea"
                              v-bind="field"
                              v-model="enrollForm.message"
                              rows="3"/>
                </Field>
              </div>
              <ErrorMessage name="message" class="recovery-label-error" />
            </div>

            <div class="reg-step-btn">
              <button type="submit">{{ $lang.app.submit }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <button class="modal-close" @click="$emit('close')"><i class="bi-x-circle"></i></button>
  </div>
</template>
<script>
import { Field, Form, ErrorMessage } from 'vee-validate';

export default {
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  props: ['application'],
  data() {
    return {
      enrollForm: {
        code: 'ENROLLED',
        message: '',
      },
    }
  },
  methods: {
    submit(values, actions) {
      this.$axios.post(`/application/status/${this.application.id}/update`, this.enrollForm).then(({data}) => {
        data.success
            ? this.$snotify.success(this.$lang.app.application_enrolled)
            : this.$snotify.error(this.$lang.app.quota_empty)
        this.$emit('refresh')
      }).catch(error => {
        if(error.response.status === 400) {
          actions.setErrors(error.response.data.errors);
        }
      })
    }
  }
}
</script>
<style>
@import '../../../assets/styles/modal.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
</style>