<template>
  <div>
    <div v-if="application" class="admin-detail">
      <div class="admin-navbar justify-between items-center">
        <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
      </div>
      <div class="admin-info">
        <div class="admin-detail-tabs">
          <a v-for="applicationTab in applicationTabs" :key="applicationTab.value" href=""
            @click.prevent="activeApplicationTab = applicationTab" class="admin-detail-tab"
            :class="{ 'admin-detail-tab-active': activeApplicationTab.value === applicationTab.value }">
            {{ applicationTab.label }}
          </a>
        </div>
        <div class="admin-detail-tab-contents">
          <Application v-if="activeApplicationTab.value === 'application'" :application-data="application"
            @refresh="fetchResource" />
          <Applicant v-if="activeApplicationTab.value === 'applicant'" :applicant="application.applicant"
            :application="application" @refresh="fetchResource" />
          <Children v-if="activeApplicationTab.value === 'children'" :children="application.children"
            :application="application" @refresh="fetchResource" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Application from "../../../components/admin/application/Application";
import Applicant from "../../../components/admin/application/Applicant";
import Children from "../../../components/admin/application/Children";
export default {
  components: {
    Applicant,
    Children,
    Application,
  },
  data() {
    return {
      activeApplicationTab: {
        value: 'application',
        label: this.$lang.app.application_info,
      },
      applicationTabs: [
        {
          value: 'application',
          label: this.$lang.app.application_info,
        },
        {
          value: 'applicant',
          label: this.$lang.app.applicant_info,
        },
        {
          value: 'children',
          label: this.$lang.app.children_info,
        },
      ],

      application: null,
    }
  },
  mounted() {
    this.fetchResource()
  },
  methods: {
    fetchResource() {
      this.$axios.get(`/application/${this.$route.params.id}`).then(({ data }) => {
        this.application = data
      })
    },
  }
}
</script>
<style>
@import '../../../assets/styles/admin/tabs.css';
@import '../../../assets/styles/admin/detail.css';
@import '../../../assets/styles/admin/info.css';
@import '../../../assets/styles/admin/navbar.css';
</style>